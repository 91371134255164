import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

// VARIABLE FOR SVGs

// const HomeIcon = (props) => (
//   <ReactSVG style={{ paddingRight: 12 }} src="/assets/home.svg" />
// );
// const ProductIcon = (props) => (
//   <ReactSVG style={{ paddingRight: 12 }} src="/assets/product.svg" />
// );
// const Customers = (props) => (
//   <ReactSVG style={{ paddingRight: 12 }} src="/assets/customer.svg" />
// );
// const Bundles = (props) => (
//   <ReactSVG style={{ paddingRight: 12 }} src="/assets/bundle.svg" />
// );
// const Collections = (props) => (
//   <ReactSVG style={{ paddingRight: 12 }} src="/assets/collection.svg" />
// );
// const Suggestions = (props) => (
//   <ReactSVG style={{ paddingRight: 12 }} src="/assets/suggestion.svg" />
// );
// const Stocks = (props) => (
//   <ReactSVG style={{ paddingRight: 12 }} src="/assets/stock.svg" />
// );
const Upgrade = (props) => (
  <ReactSVG style={{ paddingRight: 12 }} src="/assets/plan.svg" />
);
// const Settings = (props) => (
//   <ReactSVG style={{ paddingRight: 12 }} src="/assets/setting.svg" />
// );

export const NavItems = ({ collapsed }) => [
  {
    icon: (
      <Link
        to="/"
        style={
          collapsed ? { padding: "9px 29px" } : { padding: "9px 0 9px 10px" }
        }
      >
        <img src="/assets/home.svg" width="20" height="20" alt="icon" />
      </Link>
    ),
    key: "/",
    label: <Link to="/">Home</Link>,
    Plan: ["Free", "Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
  },
  {
    icon: (
      <Link
        to="/products/roi"
        style={
          collapsed ? { padding: "9px 29px" } : { padding: "9px 0 9px 10px" }
        }
      >
        {" "}
        <img src="/assets/product.svg" width="20" height="20" alt="icon" />
      </Link>
    ),

    key: "/products",
    label: "Products",
    children: [
      {
        key: "/products/roi",
        label: <Link to="/products/roi">ROI</Link>,
        Plan: ["Free", "Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
      },
      {
        key: "/products/tabular",
        label: <Link to="/products/tabular">Top Selling Products</Link>,
        Plan: ["Free", "Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
      },
      {
        key: "/products/types",
        label: <Link to="/products/types">Top Selling Types</Link>,
        Plan: ["Free", "Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
      },
      {
        key: "/products/sku",
        label: <Link to="/products/sku">Top Selling Variants</Link>,
        Plan: ["Free", "Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
      },
      {
        key: "/products/regional",
        label: <Link to="/products/regional">Top Selling Regions</Link>,
        Plan: ["Free", "Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
      },
      // {
      //   key: "/products/viewed",
      //   label: <Link to="/products/viewed">Top Viewed Products</Link>,
      //   Plan: ["Enterprise"],
      // },
    ],
    Plan: ["Free", "Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
  },
  {
    icon: (
      <Link
        to="/customers/ValuedCustomer"
        style={
          collapsed ? { padding: "9px 29px" } : { padding: "9px 0 9px 10px" }
        }
      >
        <img src="/assets/customer.svg" width="20" height="20" alt="icon" />
      </Link>
    ),
    key: "/customers",
    label: "Customers",
    children: [
      {
        key: "/customers/ValuedCustomer",
        label: <Link to="/customers/ValuedCustomer">Valuable Customers</Link>,
        Plan: ["Free", "Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
      },
      {
        key: "/customers/FrequentCustomer",
        label: <Link to="/customers/FrequentCustomer">Frequent Customers</Link>,
        Plan: ["Free", "Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
      },
      {
        key: "/customers/EarlyBuyers",
        label: <Link to="/customers/EarlyBuyers">Early Buyers</Link>,
        Plan: ["Free", "Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
      },
    ],
    Plan: ["Free", "Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
  },
  {
    icon: (
      <Link
        to="/bundles/boughttogether"
        style={
          collapsed ? { padding: "9px 29px" } : { padding: "9px 0 9px 10px" }
        }
      >
        <img src="/assets/bundle.svg" width="20" height="20" alt="icon" />
      </Link>
    ),

    key: "/bundles",
    label: "Bundles",
    children: [
      {
        key: "/bundles/boughttogether",
        label: <Link to="/bundles/boughttogether">Top 10 Sku Bundles</Link>,
        Plan: ["Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
      },
      {
        key: "/bundles/Customersfavorite",
        label: (
          <Link to="/bundles/Customersfavorite">Top 10 Customers Bundle</Link>
        ),
        Plan: ["Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
      },
    ],
    Plan: ["Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
  },
  {
    icon: (
      <Link
        to="/collections"
        style={
          collapsed ? { padding: "9px 29px" } : { padding: "9px 0 9px 10px" }
        }
      >
        <img src="/assets/collection.svg" width="20" height="20" alt="icon" />
      </Link>
    ),

    key: "/collections",
    label: <Link to="/collections">Collections</Link>,
    Plan: ["Free", "Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
  },
  {
    icon: (
      <Link
        to="/suggestions"
        style={
          collapsed ? { padding: "9px 29px" } : { padding: "9px 0 9px 10px" }
        }
      >
        <img src="/assets/suggestion.svg" width="20" height="20" alt="icon" />
      </Link>
    ),

    key: "/suggestions",
    label: <Link to="/suggestions">Suggestions</Link>,
    Plan: ["Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
  },

  {
    icon: (
      <Link
        to="/comparison/orders"
        style={
          collapsed ? { padding: "9px 29px" } : { padding: "9px 0 9px 10px" }
        }
      >
        {" "}
        <img src="/assets/bundle.svg" width="20" height="20" alt="icon" />
      </Link>
    ),

    key: "/comparison",
    label: "Comparison",
    children: [
      {
        key: "/comparison/orders",
        label: <Link to="/comparison/orders">Orders Comparison</Link>,
        Plan: ["Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
      },
      {
        key: "/comparison/revenue",
        label: <Link to="/comparison/revenue">Revenue Comparison</Link>,
        Plan: ["Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
      },
    ],
    Plan: ["Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
  },
  {
    icon: (
      <Link
        to="/stockswarehouse"
        style={
          collapsed ? { padding: "9px 29px" } : { padding: "9px 0 9px 10px" }
        }
      >
        <img src="/assets/stock.svg" width="20" height="20" alt="icon" />
      </Link>
    ),

    key: "/stockswarehouse",
    label: "Stocks & Warehouse",
    Plan: ["Free", "Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
  },
  {
    icon: <Upgrade />,
    key: "/plans",
    label: <Link to="/plans">Upgrade plan</Link>,
    Plan: ["Don't Show in any plan"],
  },
  {
    icon: (
      <Link
        to="/settings/products"
        style={
          collapsed ? { padding: "9px 29px" } : { padding: "9px 0 9px 10px" }
        }
      >
        <img src="/assets/setting.svg" width="20" height="20" alt="icon" />
      </Link>
    ),
    key: "/settings",
    label: "Settings",
    children: [
      {
        key: "/settings/products",
        label: <Link to="/settings/products">Products</Link>,
        Plan: ["Free", "Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
      },
      {
        key: "/settings/customers",
        label: <Link to="/settings/customers">Customers</Link>,
        Plan: ["Free", "Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
      },
    ],
    Plan: ["Free", "Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
  },
];

//screens objects for tracking
export const ROIActivities = {
  id: 1,
  activity_name: "ROI",
  activity_type: "GRID",
};
export const SKUActivities = {
  id: 2,
  activity_name: "SKU",
  activity_type: "GRID",
};
export const RegionalActivities = {
  id: 3,
  activity_name: "REGIONAL_STATS",
  activity_type: "GRID",
};
export const CollectionActivities = {
  id: 4,
  activity_name: "COLLECTION",
  activity_type: "GRID",
};
export const StockActivities = {
  id: 5,
  activity_name: "STOCK_AND_WAREHOUS",
  activity_type: "GRID",
};
export const DashboradActivities = {
  id: 6,
  activity_name: "DASHBOARD",
  activity_type: "GRID",
};

export const AOVData = [
  {
    title: "Average Order Value",
    description:
      "Average order value (AOV) tracks the average dollar amount spent each time a customer places an order on a website or mobile app.",
  },
  {
    title: "Purchase Frequency",
    description:
      "Purchase frequency describes the number of times that your customers make a purchase from you within a specified period of time",
  },
  {
    title: "Customer Value",
    description:
      "Customer value is best defined as how much a product or service is worth to a customer. It's a measure of all the costs and benefits associated with a product or service.",
  },
];

export const monthOfYear = [
  { label: "January", value: "01" },
  { label: "February", value: "02" },
  { label: "March", value: "03" },
  { label: "April", value: "04" },
  { label: "May", value: "05" },
  { label: "June", value: "06" },
  { label: "July", value: "07" },
  { label: "August", value: "08" },
  { label: "September", value: "09" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];

export const getLast6Months = () => {
  var monthOfYear = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
  ];

  var today = new Date();
  var d;
  var months = [];

  for (var i = today.getMonth() + 1; i >= 1; i -= 1) {
    d = new Date(today.getFullYear(), i, 1);
    const m = monthOfYear.find((m) => m.value == d.getMonth() + 1);
    if (m) {
      months.push(m);
    }
  }
  months.sort((a, b) => a.value - b.value);
  return months;
};
