import React from "react";

const SubscriptionAlert = () => {
  return (
    <div>
      <p className="text-base m-0">
        Please subscribe to a plan to start using the app!
      </p>
    </div>
  );
};

export default SubscriptionAlert;
