import React, { useContext } from "react";
import { MyContext } from "../ContextApi/MyContext";
import { ReactSVG } from "react-svg";

const PaymentPlanCards = ({
  isLoading,
  id,
  name,
  price,
  desc,
  items = [
    "Advanced Analytics & Data Insights",
    "Exclusive Beta Features",
    "Custom Feature Requests",
    "Historical Data Access",
    "Priority Support",
    "Growth Recommendations (coming soon)",
  ],
  purchasePlan,
}) => {
  const { storeData } = useContext(MyContext);
  return (
    <div className="payment-card-main shadow-2xl">
      <div>
        <h1 className="payment-card-name">{name}</h1>
        <h1 className="payment-card-desc">{desc}</h1>
        <h1 className="payment-card-price">
          {price}
          <span className="payment-card-period">/month</span>
        </h1>
        <h1 className="payment-card-second-heading">What’s included</h1>
        {items.map((item, index) => (
          <div key={index} className="flex mb-4 items-center">
            <ReactSVG src="/assets/blue-check.svg" />{" "}
            <h1 className="payment-card-items">{item}</h1>
          </div>
        ))}
      </div>

      <button
        onClick={() => purchasePlan({ id })}
        className="payment-card-button"
      >
        Choose Plan
      </button>
    </div>
  );
};

export default PaymentPlanCards;
