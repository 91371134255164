import {
  Checkbox,
  Switch,
  Divider,
  Button,
  Input,
  Tooltip,
  Skeleton,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { MyContext } from "../../ContextApi/MyContext";
import { setConfiguration, setVendor } from "../../utils/Queries";
import { useQueryClient } from "@tanstack/react-query";
import { TOASTER_ERROR, TOASTER_SUCCESS } from "../../components/Notifications";
import { useProductTypes, useVendors } from "../../hooks";

const ProductsSettings = () => {
  const queryClient = useQueryClient();
  const { setStoreData, storeData, setCurrentPage } = useContext(MyContext);

  const [vendorSubmitLoading, setVendorSubmitLoading] = useState(false);
  const [typesSubmitLoading, setTypesSubmitLoading] = useState(false);
  const [isZeroProductChecked, setIsZeroProductChecked] = useState(
    storeData.hide_zero_price_products
  );
  const [excludedVendors, setExcludedVendors] = useState(
    storeData?.vendors || []
  );
  const [excludedProductTypes, setExcludedProductTypes] = useState(
    storeData?.hidden_product_type || []
  );
  const [filteredVendors, setFilteredVendors] = useState(null);
  const [filteredProductTypes, setFilteredProductTypes] = useState(null);

  const { data: vendors, loading: vendorsLoading } = useVendors();
  const { data: productTypesData, loading: productTypesLoading } =
    useProductTypes();
  // const { data: storeProducts } = useStoreProducts();

  const onChangeSwitch = (checked) => {
    setIsZeroProductChecked(checked);
  };
  const onTypeChange = (data) => {
    const target = data.target.value;
    let newTypes = [...excludedProductTypes];

    if (newTypes.includes(target)) {
      newTypes = newTypes.filter((v) => v != target);
    } else {
      newTypes.push(target);
    }

    setExcludedProductTypes(newTypes);
  };

  const onVendorChange = (data) => {
    const target = data.target.value;
    let newVendors = [...excludedVendors];

    if (newVendors.includes(target)) {
      newVendors = newVendors.filter((v) => v != target);
    } else {
      newVendors.push(target);
    }

    setExcludedVendors(newVendors);
  };

  const saveVendors = async () => {
    try {
      setVendorSubmitLoading(true);
      const data = await setVendor({ data: { vendors: excludedVendors } });
      setStoreData(data.storeData);
      setVendorSubmitLoading(false);
      queryClient.invalidateQueries();

      TOASTER_SUCCESS({
        message: "Excluded Vendors Updated!",
        duration: 3,
      });
    } catch (error) {
      setVendorSubmitLoading(false);
      TOASTER_ERROR({
        message: error.message,
        duration: 3,
      });
    }
  };
  const saveTypes = async () => {
    try {
      setTypesSubmitLoading(true);
      const data = await setConfiguration({
        data: {
          hide_products: isZeroProductChecked,
          hidden_product_type: excludedProductTypes,
          // excluded_customers: customers,
        },
      });

      setStoreData(data.storeData);
      setTypesSubmitLoading(false);
      queryClient.invalidateQueries();

      TOASTER_SUCCESS({
        message: data.message,
        duration: 3,
      });
    } catch (error) {
      setTypesSubmitLoading(false);
      TOASTER_ERROR({
        message: error.message,
        duration: 3,
      });
    }
  };

  const onTypeSearch = (e) => {
    const value = String(e.target.value)?.toLowerCase()?.trim() || "";
    setFilteredProductTypes(
      productTypesData?.productTypes?.filter((type) =>
        String(type)?.toLowerCase()?.includes(value)
      )
    );
  };
  const onVendorSearch = (e) => {
    const value = String(e.target.value)?.toLowerCase()?.trim() || "";
    setFilteredVendors(
      vendors?.filter((vendor) =>
        String(vendor)?.toLowerCase()?.includes(value)
      )
    );
  };

  useEffect(() => {
    if (vendors) {
      setFilteredVendors(vendors);
    } else {
      setFilteredVendors(null);
    }
  }, [vendors]);

  useEffect(() => {
    if (productTypesData?.productTypes) {
      setFilteredProductTypes(productTypesData?.productTypes);
    } else {
      setFilteredProductTypes(null);
    }
  }, [productTypesData?.productTypes]);

  useEffect(() => {
    setCurrentPage(window.location.pathname);
  }, []);

  return (
    <div className="flex flex-col justify-between h-full w-full">
      <div>
        {/* <PageHeading heading="Product Settings" /> */}
        <div className="p-4 bg-white mb-4 rounded-lg min-h-[60vh] flex flex-col">
          <div>
            <div className="flex justify-between items-center gap-4">
              <span className="side-modal-vendorHeading">Excluded Vendors</span>
              <Input
                className="!w-60 !border-2 border-solid !rounded"
                placeholder="Search"
                onChange={onVendorSearch}
                allowClear
              />
            </div>
            <Divider className="!mt-4 !mb-0" />
          </div>

          <div className="flex flex-wrap gap-2 py-2 max-h-[50vh] customScrollbar">
            {!vendorsLoading && filteredVendors != null ? (
              filteredVendors?.map((vendor, index) => (
                <div key={index}>
                  <Checkbox
                    className="w-80 bg-slate-50 !p-2 hover:bg-slate-100 hover:shadow rounded"
                    value={vendor}
                    onChange={onVendorChange}
                    checked={excludedVendors?.includes(vendor)}
                  >
                    {vendor}
                  </Checkbox>
                </div>
              ))
            ) : (
              <SkeletonInput />
            )}
          </div>
          <div className="mt-auto">
            <Divider className="!mb-4 !mt-0" />
            <Button
              type="primary"
              className="ml-auto !block w-44 !font-extrabold !rounded"
              onClick={saveVendors}
              loading={vendorSubmitLoading}
              disabled={vendorSubmitLoading}
            >
              Save
            </Button>
          </div>
        </div>

        <div className="p-4 bg-white mb-4 rounded-lg min-h-[60vh] flex flex-col">
          <div>
            <div className="flex justify-between items-center gap-4">
              <span className="side-modal-vendorHeading">
                Product type that you don't want to show in results
              </span>
              <Input
                className="!w-60 !border-2 border-solid !rounded"
                placeholder="Search"
                onChange={onTypeSearch}
                allowClear
              />
            </div>
            <Divider className="!mt-4 !mb-0" />
          </div>
          <div className="flex flex-wrap gap-2 py-2 max-h-[50vh] customScrollbar">
            {!productTypesLoading && filteredProductTypes != null ? (
              filteredProductTypes?.map((type, index) => (
                <div key={index}>
                  <Checkbox
                    className="w-80 bg-slate-50 !p-2 hover:bg-slate-100 hover:shadow rounded"
                    value={type}
                    onChange={onTypeChange}
                    checked={excludedProductTypes?.includes(type)}
                  >
                    {type.length > 30 ? (
                      <Tooltip title={type}>
                        <span>{type?.substring(0, 30)}...</span>
                      </Tooltip>
                    ) : (
                      type
                    )}
                  </Checkbox>
                </div>
              ))
            ) : (
              <SkeletonInput />
            )}
          </div>
          <div className="mt-auto">
            <Divider className="!mb-4 !mt-0" />
            <div className="mb-4 flex justify-between items-center">
              <div>
                <span className="side-modal-vendortext">
                  Hide zero price products from your analysis :{" "}
                </span>
                <Switch
                  className="!mr-4"
                  checked={isZeroProductChecked}
                  onChange={onChangeSwitch}
                />
              </div>

              <Button
                type="primary"
                className="ml-auto !block w-44 !font-extrabold !rounded"
                onClick={saveTypes}
                loading={typesSubmitLoading}
                disabled={typesSubmitLoading}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsSettings;

function SkeletonInput({ width = 80 }) {
  return (
    <div className="flex flex-wrap gap-2 h-[50vh]">
      <Skeleton.Input active={true} className={`!w-${width}`} />
      <Skeleton.Input active={true} className={`!w-${width}`} />
      <Skeleton.Input active={true} className={`!w-${width}`} />
      <Skeleton.Input active={true} className={`!w-${width}`} />
      <Skeleton.Input active={true} className={`!w-${width}`} />
      <Skeleton.Input active={true} className={`!w-${width}`} />
      <Skeleton.Input active={true} className={`!w-${width}`} />
      <Skeleton.Input active={true} className={`!w-${width}`} />
      <Skeleton.Input active={true} className={`!w-${width}`} />
      <Skeleton.Input active={true} className={`!w-${width}`} />
      <Skeleton.Input active={true} className={`!w-${width}`} />
      <Skeleton.Input active={true} className={`!w-${width}`} />
      <Skeleton.Input active={true} className={`!w-${width}`} />
      <Skeleton.Input active={true} className={`!w-${width}`} />
      <Skeleton.Input active={true} className={`!w-${width}`} />
      <Skeleton.Input active={true} className={`!w-${width}`} />
      <Skeleton.Input active={true} className={`!w-${width}`} />
      <Skeleton.Input active={true} className={`!w-${width}`} />
    </div>
  );
}
