import React from "react";

const Loader = ({ widht = 5, height = 5, color = "border-green-500" }) => {
  return (
    <div className="h-screen flex flex-col justify-center items-center">
      <img
        src="/assets/loader.gif"
        alt="Loading..."
        width="500px"
        height="500px"
      />
    </div>
  );
};

export default Loader;
