import { Button, Layout, Menu, Skeleton, Spin } from "antd";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { Route, Routes, useSearchParams } from "react-router-dom";
import StoreStatus from "../components/StoreStatus";
import { MyContext } from "../ContextApi/MyContext";
import AllRoutes from "../routes/index";
import { NavItems } from "../utils/constantObjects";
import { notificationError, notificationSuccess } from "../utils/helper";
import { confirmPayment, getDateRangeLimit } from "../utils/Queries";

import { MainHeader } from "../components/Header";
import { ReactSVG } from "react-svg";
import { useStore } from "../hooks";
import Loader from "../components/Loader";
import Plan from "../pages/plan/index.js";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
const { Content, Sider } = Layout;

const Index = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  let token = searchParams.get("token");

  const [isLoading, setIsLoading] = useState(true);
  const [errorOccured, setErrorOccured] = useState(false);
  const [localroutes, setLocalroutes] = useState([]);
  const [defaultOpen, setDefaultOpen] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  // const { status, data: storeData, error, isFetching } = useGetStore();

  const {
    setStoreData,
    currentPage,
    setCurrentPage,
    setCities,
    setMaxStartDate,
  } = useContext(MyContext);

  const handleNav = (e) => {
    if (e.key == "/") setCurrentPage(e.key);
  };

  const {
    error: storeError,
    data: storeData,
    isFetching: isGettingStore,
    refetch: refetchStore,
  } = useStore({
    onSuccess: async (data) => {
      setStoreData(data);
      const filteredRoutes = AllRoutes.filter(({ Plan }) =>
        Plan.includes(data?.StorePlan?.Plan?.name)
      );
      const routeComponents = filteredRoutes.map(({ path, component }, key) => (
        <Route exact path={path} element={component} key={key} />
      ));
      setLocalroutes(routeComponents);
      // getStoreData();
      await getDateRangeLimitFunc();
      setIsLoading(false);
    },
    onError: (error) => {
      if (error.response.status == 401 && localStorage.getItem("accessToken")) {
        refetchStore();
      } else {
        setErrorOccured(true);
        setIsLoading(false);
      }
    },
  });

  const getDateRangeLimitFunc = async () => {
    try {
      const resp = await getDateRangeLimit();
      setMaxStartDate(resp.startDate);
    } catch (err) {
      console.log(err);
      notificationError({
        description: "Failed to get date range limit",
      });
    }
  };
  const paymentConfirmation = async ({ charge_id }) => {
    try {
      const data = await confirmPayment({ charge_id });
      setStoreData(data?.store);
      notificationSuccess({ message: data.message });
    } catch (err) {
      notificationError({ message: err.message });
      console.log(err);
    }
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    setIsLoading(true);
    if (window.location.search.split("charge_id=")[1]) {
      paymentConfirmation({
        charge_id: window.location.search.split("charge_id=")[1],
      });
      searchParams.delete("charge_id");
      setSearchParams(searchParams);
      setCurrentPage(window.location.pathname);
    }
    if (token) {
      localStorage.setItem("accessToken", token);
      searchParams.delete("token");
      setSearchParams(searchParams);
    }
    setCurrentPage(window.location.pathname);
    const defaultOpen = "/" + window.location.pathname.split("/")[1];
    setDefaultOpen(defaultOpen);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading ? (
    <Loader />
  ) : !isLoading && errorOccured ? (
    <div className="flex flex-col items-center justify-center h-screen">
      <p className="text-center text-base">
        Unable to fetch data. Please try again later or contact us at{" "}
        <a
          href="mailto:info@selli.solutions"
          target="_blank"
          className="underline hover:underline focus:underline"
        >
          info@selli.solutions
        </a>
      </p>
    </div>
  ) : storeData?.uninstalled || !localStorage.getItem("accessToken") ? (
    <StoreStatus
      image={
        <ReactSVG style={{ paddingRight: 12 }} src="/assets/uninstall.svg" />
      }
      title="Hang tight and follow directions below!"
      paragraph={
        <p>
          If you’ve installed the app, please launch it via your store. Need
          assistance? Reach out to us at{" "}
          <a
            href="mailto:info@selli.solutions"
            target="_blank"
            className="underline hover:underline focus:underline"
          >
            info@selli.solutions
          </a>{" "}
          anytime!
        </p>
      }
    />
  ) : storeData?.StorePlan?.Plan?.name != "Free" &&
    storeData?.payment_status == "CANCELLED" ? (
    <StoreStatus
      image={
        <ReactSVG
          style={{ paddingRight: 12 }}
          src="/assets/payment-cancel.svg"
        />
      }
      title="Payment Failed"
      paragraph={`Sorry, your payment was failed for this month.
Please try again later or contact Selli support. Once the payment is done your app will automatically start working.`}
    />
  ) : storeData?.StorePlan?.Plan?.name != "Free" &&
    (storeData?.payment_status == "" ||
      storeData?.payment_status == "DECLINED") ? (
    <Plan />
  ) : // 			: !isGettingStore &&
  //   storeData?.StorePlan?.Plan?.name == "Free" &&
  //   storeData?.trial_expired ? (
  // 	<Suspense
  // 		fallback={
  // 			<div className="flex flex-col items-center justify-center h-screen"></div>
  // 		}
  // 	>
  // 		{window.location.pathname.includes("plans") || upgradeButtonPressed ? (
  // 			<Plans />
  // 		) : (
  // 			<TrialExpired setUpgradeButtonPressed={setUpgradeButtonPressed} />
  // 		)}
  // 	</Suspense>
  // 			)
  !storeData?.data_fetched ? (
    <StoreStatus
      image={
        <>
          <Spin size="large" />
          <br />
          <p className="subscription-card-loading-text">Loading...</p>
        </>
      }
      title=""
      paragraph={`Hi, We are fetching your data from Selli. Once the data is fetched we will send you an email on ${storeData.shop_email}. It can take a while your patience is required.`}
    />
  ) : (
    <Layout className="h-screen">
      <MainHeader
        storeName={String(storeData?.store_url || "")
          .split(".myshopify")[0]
          .replace(/-/g, " ")}
        lastSync={String(storeData?.last_sync)}
      />

      <Layout>
        <Sider
          width={300}
          className="side-navigation-main-div customScrollbar customSiderScrollbar"
          collapsed={collapsed}
          trigger={null}
        >
          <div
            className={`flex ${
              collapsed ? "justify-center" : "justify-end pr-[10px]"
            }`}
          >
            <Button
              style={{ height: "30px", width: "30px", marginLeft: "2px" }}
              type="text"
              color="white"
              onClick={toggleCollapsed}
              icon={
                collapsed ? (
                  <img src="/assets/open.png" width="30px" height="30px" />
                ) : (
                  <img src="/assets/close.png" width="30px" height="30px" />
                )
              }
            ></Button>
          </div>
          <Menu
            onClick={handleNav}
            mode="inline"
            openKeys={collapsed ? [] : undefined}
            selectedKeys={[currentPage]}
            defaultSelectedKeys={["/"]}
            defaultOpenKeys={[defaultOpen]}
            items={NavItems({
              storeData,
              collapsed,
            })
              .filter(({ Plan }) =>
                Plan.includes(storeData?.StorePlan?.Plan?.name)
              )
              .map((item) => {
                return {
                  ...item,
                  title: collapsed ? null : item.label,
                  children: item?.children?.filter(({ Plan, key }) => {
                    return (
                      Plan.includes(storeData?.StorePlan?.Plan?.name) &&
                      (storeData?.useStagingFeatures ||
                        key != "/customers/EarlyBuyers")
                    );
                  }),
                };
              })}
            className="side-bar-menu"
          />
        </Sider>

        <Content className="p-4 overflow-y-auto overflow-x-hidden customScrollbar">
          <Suspense
            fallback={
              <div className="h-[90vh] flex items-center">
                <div className="inline-block -rotate-90 !w-[70vh]">
                  <Skeleton
                    active
                    round={true}
                    paragraph={{ rows: 5, width: [220, 260, 300, 310, 350] }}
                    title={false}
                  />
                </div>
                <div className="inline-block -rotate-90 !w-[70vh]">
                  <Skeleton active round={true} paragraph={{ rows: 6 }} />
                </div>
                <div className="inline-block -rotate-90 !w-[90vh]">
                  <Skeleton active round={true} paragraph={{ rows: 4 }} />
                </div>
              </div>
            }
          >
            <Routes>{localroutes}</Routes>
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Index;
