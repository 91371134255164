import {
  CheckCircleFilled,
  CloseCircleFilled,
  InfoCircleFilled,
} from "@ant-design/icons";
import { notification } from "antd";

notification.config({
  maxCount: 1,
});

export const TOASTER_INFO = ({ message, description, duration = 5 }) => {
  return notification.warning({
    message,
    description: description,
    style: {
      fontWeight: "500",
      minWidth: 320,
      width: window.innerWidth >= 568 ? 560 : 380,
      backgroundColor: "#fffbe6",
      border: "1px solid #ffe58f",
      margin: 0,
      marginBottom: "20px",
      boxShadow: "0 2px 17px 3px #ffe58f",
      top: "20px",
    },
    icon: <InfoCircleFilled style={{ color: "#f9bf02" }} />,
    placement: "bottomRight",
    duration: duration,
  });
};

//this is shown from top right corner and stays there till the api repose is updated to let cutomer know that the api is taking time to update
export const TOASTER_ERROR = ({
  message = "",
  description = "",
  duration = 5,
}) => {
  return notification.error({
    message,
    description,
    duration: duration,
    style: {
      minWidth: 320,
      width: window.innerWidth >= 568 ? 560 : 380,
      backgroundColor: "#fff1f0",
      border: "1px solid #ffa39e",
      margin: 0,
      marginBottom: "20px",
      boxShadow: "0 2px 17px 3px #ffa39e",
      top: "20px",
    },
    icon: <CloseCircleFilled style={{ color: "#f5222e" }} />,
    placement: "bottomRight",
  });
};

//this is shown from top right corner and stays there till the api repose is updated to let cutomer know that the api is taking time to update
export const TOASTER_SUCCESS = ({
  message = "",
  description = "",
  duration = 5,
}) => {
  return notification.warning({
    message,
    description,
    duration: duration,
    style: {
      minWidth: 320,
      width: window.innerWidth >= 568 ? 560 : 380,
      backgroundColor: "#F6FFED",
      border: "1px solid #B7EB8F",
      margin: 0,
      marginBottom: "20px",
      boxShadow: "0 2px 17px 3px #B7EB8F",
      top: "20px",
    },
    icon: <CheckCircleFilled style={{ color: "#52C51A" }} />,
    placement: "bottomRight",
  });
};

// this is a small toaster that comes from bottom which is shown few sec when an api takes time to update
export const NOTIFICATION_TOASTER = ({
  messageApi,
  content = "",
  className = "",
  type = "success",
}) => {
  messageApi.open({
    type,
    content,
    className,
    style: {
      marginTop: "85vh",
      padding: "10px 20px",
      fontSize: "16px",
      fontWeight: "500",
    },
  });
};
