import React from "react";
import CustomersSettings from "../pages/settings/CustomersSettings";
import ProductsSettings from "../pages/settings/ProductsSettings";
const Orders = React.lazy(() => import("../pages/comparison/orders"));
const Revenue = React.lazy(() => import("../pages/comparison/revenue"));
const Dashboard = React.lazy(() => import("../pages/dashboard/index"));
const NotFound = React.lazy(() => import("../pages/NotFound"));

//products
const ProductsRoi = React.lazy(() => import("../pages/products/Roi"));
const ProductsSku = React.lazy(() => import("../pages/products/Sku"));
const ProductsTabular = React.lazy(() => import("../pages/products/Tabular"));
const ProductsRegional = React.lazy(() => import("../pages/products/Regional"));
const ProductTypes = React.lazy(() => import("../pages/products/ProductTypes"));
const ProductViewed = React.lazy(() =>
  import("../pages/products/ProductViewed")
);
//products

//Customer
const ValueAble = React.lazy(() => import("../pages/customers/Valueable"));
const Frequent = React.lazy(() => import("../pages/customers/Frequent"));
const FirstBuyers = React.lazy(() => import("../pages/customers/FirstBuyers"));

//bundles
const BoughtTogether = React.lazy(() =>
  import("../pages/bundles/BoughtTogether")
);
const CustomerBundle = React.lazy(() =>
  import("../pages/bundles/CustomerBundle")
);

//collections
const CollectionMain = React.lazy(() => import("../pages/collections"));
//collections

//stockswarehouse
const StocksWarehouse = React.lazy(() => import("../pages/stoksAndWarehouses"));
//stockswarehouse

//plans
const Plans = React.lazy(() => import("../pages/plan"));
const Suggestions = React.lazy(() => import("../pages/suggestions"));

const Routes = [
  {
    path: "/",
    name: "/home",
    component: <Dashboard />,
    Plan: ["Free", "Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
  },
  {
    path: "/products/roi",
    name: "/products/roi",
    component: <ProductsRoi />,
    Plan: ["Free", "Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
  },
  {
    path: "/products/sku",
    name: "/products/sku",
    component: <ProductsSku />,
    Plan: ["Free", "Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
  },
  {
    path: "/products/tabular",
    name: "/products/tabular",
    component: <ProductsTabular />,
    Plan: ["Free", "Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
  },
  {
    path: "/products/types",
    name: "/products/types",
    component: <ProductTypes />,
    Plan: ["Free", "Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
  },
  {
    path: "/products/regional",
    name: "/products/regional",
    component: <ProductsRegional />,
    Plan: ["Free", "Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
  },
  {
    path: "/products/viewed",
    name: "/products/viewed",
    component: <ProductViewed />,
    Plan: ["Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
  },
  {
    path: "/customers/ValuedCustomer",
    name: "/customers/ValuedCustomer",
    component: <ValueAble />,
    Plan: ["Free", "Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
  },
  {
    path: "/customers/FrequentCustomer",
    name: "/customers/FrequentCustomer",
    component: <Frequent />,
    Plan: ["Free", "Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
  },
  {
    path: "/customers/EarlyBuyers",
    name: "/customers/EarlyBuyers",
    component: <FirstBuyers />,
    Plan: ["Free", "Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
  },
  {
    path: "/bundles/boughttogether",
    name: "/bundles/boughttogether",
    component: <BoughtTogether />,
    Plan: ["Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
  },
  {
    path: "/bundles/Customersfavorite",
    name: "/bundles/Customersfavorite",
    component: <CustomerBundle />,
    Plan: ["Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
  },
  {
    path: "/collections",
    name: "/collections",
    component: <CollectionMain />,
    Plan: ["Free", "Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
  },
  {
    path: "/comparison/orders",
    name: "/comparison/orders",
    component: <Orders />,
    Plan: ["Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
  },
  {
    path: "/comparison/revenue",
    name: "/comparison/revenue",
    component: <Revenue />,
    Plan: ["Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
  },

  {
    path: "/stockswarehouse",
    name: "/stockswarehouse",
    component: <StocksWarehouse />,
    Plan: ["Free", "Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
  },
  {
    path: "/plans",
    name: "/plans",
    component: <Plans />,
    Plan: ["Don't Show in any plan"],
  },
  {
    path: "/suggestions",
    name: "/suggestions",
    component: <Suggestions />,
    Plan: ["Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
  },
  {
    path: "/settings/customers",
    name: "/settings/customers",
    component: <CustomersSettings />,
    Plan: ["Free", "Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
  },
  {
    path: "/settings/products",
    name: "/settings/products",
    component: <ProductsSettings />,
    Plan: ["Free", "Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
  },
  {
    path: "*",
    name: "/notFound",
    component: <NotFound />,
    Plan: ["Free", "Basic", "Shopify", "Advanced", "Enterprise", "Custom"],
  },
];

export default Routes;
