import { notification } from "antd";

export const returnCurrenecySymbol = (currency) => {
  switch (currency) {
    case "USD":
      return "$";
    case "EUR":
      return "€";
    case "GBP":
      return "£";
    case "AUD":
      return "$";
    default:
      return "$";
  }
};
export const getMonthName = (monthNumber) => {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString("en-US", { month: "long" });
};

export const getCssVariableValue = (cssvariable) => {
  return getComputedStyle(document.documentElement).getPropertyValue(
    cssvariable
  );
};

export const notificationError = ({
  message = "",
  description = "",
  placement = "bottomLeft",
}) => {
  notification.error({
    message,
    description,
    placement,
    style: {
      background: "lightcoral",
    },
  });
};

export const notificationSuccess = ({
  message = "",
  description = "",
  placement = "bottomLeft",
}) => {
  notification.success({
    message,
    description,
    placement,
    style: {
      background: "lightgreen",
    },
  });
};
export const getConfig = ({
  data,
  yAxisLabel,
  xAxisLabel,
  formatter,
  maxYAxis,
  color,
}) => {
  return {
    data,
    xField: "x_axis",
    yField: "y_axis",
    renderer: "svg",
    tooltip: {
      customContent: (title, items) => {
        return (
          <>
            <h5 style={{ marginTop: 16 }}>
              {xAxisLabel}: {title}
            </h5>
            <ul style={{ paddingLeft: 0 }}>
              {items?.map((item, index) => {
                const { value, color } = item;
                return (
                  <li
                    key={item.year}
                    className="g2-tooltip-list-item"
                    data-index={index}
                    style={{
                      marginBottom: 4,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      className="g2-tooltip-marker"
                      style={{ backgroundColor: color }}
                    ></span>
                    <span
                      style={{
                        display: "inline-flex",
                        flex: 1,
                        justifyContent: "space-between",
                      }}
                    >
                      <span style={{ marginRight: 16 }}>{yAxisLabel}:</span>
                      <span className="g2-tooltip-list-item-value">
                        {value}
                      </span>
                    </span>
                  </li>
                );
              })}
            </ul>
          </>
        );
      },
    },
    point: {
      size: 5,
      shape: "diamond",
      style: {
        fill: "white",
        stroke: "#2593fc",
        lineWidth: 2,
      },
    },

    yAxis: {
      label: {
        formatter,
      },
      max: maxYAxis,

      title: {
        visible: false,
        text: yAxisLabel,
      },
    },

    xAxis: {
      tickCount: 5,
      title: {
        visible: true,
        text: xAxisLabel,
      },
      label: {
        style: {
          fontStyle: "italic",
          fontWeight: "bold",
        },

        // offsetX: 0,
        // offsetY: 20,
        // rotate: -1,
        // autoEllipsis: true,
      },
    },

    columnStyle: {
      radius: [3, 3, 0, 0],
    },

    smooth: true,
    color,
  };
};

export const getCurrencySymbol = (currency) => {
  return !currency
    ? "$"
    : (0)
        .toLocaleString(undefined, {
          style: "currency",
          currency: currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
        .replace(/\d/g, "")
        .trim();
};

export const getNumberWithCommas = (num) => {
  return Number(num).toLocaleString("en", { useGrouping: true });
};

export const getCustomTooltipForRevenue = (currency, title, items) => {
  return (
    <>
      <h5 style={{ marginTop: 16 }}>{title}</h5>
      <ul style={{ paddingLeft: 0 }}>
        {items?.map((item, index) => {
          const { value, color } = item;
          return (
            <li
              key={item.year}
              className="g2-tooltip-list-item"
              data-index={index}
              style={{
                marginBottom: 4,
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                className="g2-tooltip-marker"
                style={{ backgroundColor: color }}
              ></span>
              <span
                style={{
                  display: "inline-flex",
                  flex: 1,
                  justifyContent: "space-between",
                }}
              >
                <span style={{ marginRight: 16 }}>Revenue:</span>
                <span className="g2-tooltip-list-item-value">
                  {getCurrencySymbol(currency)} {value}
                </span>
              </span>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export const scrollToTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
};
