import React, { useContext } from "react";
import { ReactSVG } from "react-svg";
import { subscribePromotionalPlan } from "../utils/Queries";
import { TOASTER_ERROR, TOASTER_SUCCESS } from "./Notifications";
import { MyContext } from "../ContextApi/MyContext";

const CustomPlanCard = ({
  name,
  desc,
  setLoading,
  items = [
    "Advanced Analytics & Data Insights",
    "Limited Historical Data Access",
    "Support",
  ],
}) => {
  const { storeData } = useContext(MyContext);

  const subscribePlan = async () => {
    try {
      setLoading(true);

      const { message } = await subscribePromotionalPlan();
      TOASTER_SUCCESS({ message });
      window.location.href = "/";
      setLoading(false);
    } catch (err) {
      setLoading(false);

      TOASTER_ERROR({ message: err.message });
    }
  };
  return (
    <div className="payment-card-main shadow-2xl">
      <div>
        <h1 className="payment-card-name">{name}</h1>
        <h1 className="payment-card-desc">{desc}</h1>
        <h1 className="payment-card-price">FREE</h1>
        <h1 className="current-payment-card-price"></h1>
        <h1 className="payment-card-second-heading">What’s included</h1>
        {items.map((item, index) => (
          <div key={index} className="flex mb-4 items-center">
            <ReactSVG src="/assets/blue-check.svg" />{" "}
            <h1 className="payment-card-items">{item}</h1>
          </div>
        ))}
      </div>

      <button onClick={subscribePlan} className="payment-card-button">
        Choose Plan
      </button>
    </div>
  );
};

export default CustomPlanCard;
